import React from 'react'
import {Box, Text,Heading} from '@chakra-ui/react'
import MySEO from '../components/seo'


const Datenschutz = () => {

    return(
        <div>
             <MySEO
        title="Datenschutz von Haben oder Sein"
        description="Datenschutz Erläuterungen"
      />
        <Box>
            <Heading># Datenschutz | <span style={{color:"grey", fontWeight:200}}>data protection</span></Heading>
        <Text>
           Diese Webseite ermittelt keine privaten Daten und kann deshalb auch keine Daten an Dritte weitergeben. <br/>
           Wenn externe Inhalte angeboten werden (Vimeo Film), können Daten von Ihnen von der Vimeoplattform ermittelt werden.
           Externe Inhalte werden nur mit ihrer Einwilligung zu unseren Datenschutzbestimmungen angeboten.

           Für die technische Realisierung einer Internetseite müssen technische Daten ermittelt werden, wie z.B. die IP Adresse oder das benutzte Endgerät.
           Diese Daten können nicht mit Ihnen in Verbindung gesetzt werden. In der Regel werden sie nach kurzer Zeit automatisch gelöscht.

           Für Verlinkungen von unserer Seite schließen wir jede Verantwortung aus, ebenso für Verlinkungen von dritter Seite auf Hasenoderbein.

        </Text>

        </Box>
        </div>
    )}
    export default Datenschutz
